export enum SETTING_FIELD {
  MINIMUM_NUMBER_OF_REVIEW = "minimum_number_of_review",
  MAXIMUM_PERCENT_OF_DISLIKE = "maximum_percent_of_dislike",
}

export const NEXT_STEP_DELAY_TIME = 1000;

export const TUTORIAL_POPUP_DISPLAY_TIME = 2000;

export const CONTRACTS_PAGINATION_SIZE = 5;

export const REVIEWS_PAGINATION_SIZE = 10;

export const RESOURCES_PAGINATION_SIZE = 1000;

export const IMAGES_LIMIT_NUMBER = 20;

export const IMAGES_TYPE_ALLOWED = ["image/png", "image/jpeg", "image/heic"];

export const DEFAULT_VALUE_AGE = 30;

export const DEFAULT_VALUE_MONTHLY_LIVING_EXPENSES = 25;
