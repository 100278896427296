import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { LayoutWrapper } from "./styles";

const MainLayout = () => {
  return (
    <LayoutWrapper hasSider>
      <Sidebar />
      <Layout>
        <Header />
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </LayoutWrapper>
  );
};

export default MainLayout;
