import styled from "styled-components";

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  .loading-icon {
    font-size: 40px;
  }
`;
