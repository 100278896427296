export enum COMMENT_STATUS {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum COMMENT_FIELD {
  ID = "id",
  OWNER_ID = "ownerId",
  READERS = "readers",
  CONTENT = "content",
  TITLE = "title",
  STATUS = "status",
  REASON = "reason",
  OWNER = "owner",
  REACTION = "reaction",
  CONTRACT_ID = "contractId",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
}
