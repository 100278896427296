import { THEME_ADMIN } from "@milize/common/constants";
import { ConfigProvider } from "antd";
import jaJP from "antd/lib/locale/ja_JP";
import AppLocale, { DEFAULT_LOCALE } from "config/translation";
import React from "react";
import { IntlProvider } from "react-intl";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./GlobalStyle";
import ReAuthenticate from "./ReAuthenticate";
import AppRoutes from "./routes";
import store from "./store";

const App: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={THEME_ADMIN}>
        <ConfigProvider locale={jaJP}>
          <GlobalStyle />

          <IntlProvider
            locale={DEFAULT_LOCALE}
            defaultLocale={DEFAULT_LOCALE}
            messages={AppLocale[DEFAULT_LOCALE].messages}
          >
            <ReAuthenticate>
              <AppRoutes />
            </ReAuthenticate>
          </IntlProvider>
        </ConfigProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};

export default App;
