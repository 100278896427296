import { Table } from "antd";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  background-color: white;

  .ant-table {
    &-cell {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.black.main};
      background-color: white;
      border-color: ${({ theme }) => theme.colors.gray["600"]};
      padding: 26px 30px;
    }

    &-thead .ant-table-cell {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.gray["300"]};
      line-height: 17px;
      padding-top: 12px;
      padding-bottom: 12px;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          white-space: nowrap;
          max-width: 15vw;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }
      }
    }

    // sorter / filter
    &-column-sorters {
      justify-content: flex-start;
    }

    &-column-title {
      white-space: nowrap;
      margin-right: 12px;
      flex: 0;
    }

    &-filter-column {
      justify-content: flex-start;
    }
  }

  .ant-pagination {
    margin: 0;
    margin-top: 30px;
    margin-right: 32px;

    &-total-text {
      margin-right: 16px;
    }
  }
`;
