import jaJP from "antd/es/locale/ja_JP";
import { LOCALE } from "@milize/common/constants";

import jaMessagesCommon from "@milize/common/locales/ja_JP.json";
import jaMessages from "../locales/ja_JP.json";

const JaLang = {
  messages: {
    ...jaMessagesCommon,
    ...jaMessages,
  },
  antd: jaJP,
  locale: LOCALE.ja,
};

export default JaLang;
