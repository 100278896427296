import { ReactComponent as ContractIcon } from "../assets/images/ic_contract.svg";
import { ReactComponent as FpIcons } from "../assets/images/ic_fp.svg";
import { ReactComponent as OverviewIcon } from "../assets/images/ic_overview.svg";
import { ReactComponent as ProfileIcon } from "../assets/images/ic_profile.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/ic_setting.svg";
import { ReactComponent as UserIcon } from "../assets/images/ic_users.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/ic_logout.svg";

import { SidebarMenu } from "../types/routes.types";

export enum ROUTES_ADMIN {
  HOME = "/",
  SIGN_IN = "/login",
  SIGN_UP = "/register",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  USERS = "/users",
  FPS = "/fps",
  OPERATORS = "/operators",
  CONTRACTS = "/contracts",
  SETTINGS = "/settings",
  PROFILE = "/profile",
  VERIFY = "/verify",
  CONVERT_CONTRACT = "/contracts/convert/:contractId",
  DETAIL_CONTRACT = "/contracts/detail/:contractId",
  NOT_FOUND = "/404",
  LOGOUT = "/LOGOUT",
  UPDATE_CONTRACT = "/contracts/update/:contractId",
  COMPANIES = "/companies",
  CONTRACT_TYPE = "/contract-type",
}

export const WIDTH_SIDEBAR = 255;

export const SIDEBAR_MENU_ADMIN: SidebarMenu[] = [
  {
    id: 1,
    label: "sidebar.menu.overview",
    path: ROUTES_ADMIN.HOME,
    icon: OverviewIcon,
  },
  {
    id: 2,
    label: "sidebar.menu.users",
    path: ROUTES_ADMIN.USERS,
    icon: UserIcon,
  },
  {
    id: 3,
    label: "sidebar.menu.fps",
    path: ROUTES_ADMIN.FPS,
    icon: FpIcons,
  },
  {
    id: 4,
    label: "sidebar.menu.operators",
    path: ROUTES_ADMIN.OPERATORS,
    icon: ProfileIcon,
  },
  {
    id: 5,
    label: "sidebar.menu.contracts",
    path: ROUTES_ADMIN.CONTRACTS,
    icon: ContractIcon,
  },
  {
    id: 6,
    label: "sidebar.menu.settings",
    path: ROUTES_ADMIN.SETTINGS,
    icon: SettingsIcon,
    // disabled: false,
  },
  {
    id: 7,
    label: "sidebar.menu.logout",
    icon: LogoutIcon,
  },
];

export const SIDEBAR_MENU_FP_OPERATOR: SidebarMenu[] = [
  {
    id: 1,
    label: "sidebar.menu.contracts",
    path: ROUTES_ADMIN.CONTRACTS,
    icon: ContractIcon,
  },
  {
    id: 2,
    label: "sidebar.menu.profile",
    path: ROUTES_ADMIN.PROFILE,
    icon: ProfileIcon,
  },
  {
    id: 3,
    label: "sidebar.menu.logout",
    icon: LogoutIcon,
  },
];

export const HEADER_MENU_FP_OPERATOR = [
  {
    label: "sidebar.menu.contracts.detail",
    path: "/contracts/detail",
  },
  {
    label: "sidebar.menu.contracts",
    path: ROUTES_ADMIN.CONTRACTS,
  },
  {
    label: "sidebar.menu.profile",
    path: ROUTES_ADMIN.PROFILE,
  },
];
export const HEADER_MENU_ADMIN = [
  {
    label: "sidebar.menu.contracts.detail",
    path: "/contracts/detail",
  },
  {
    label: "sidebar.menu.overview",
    path: ROUTES_ADMIN.HOME,
  },
  {
    label: "sidebar.menu.users",
    path: ROUTES_ADMIN.USERS,
  },
  {
    label: "sidebar.menu.fps",
    path: ROUTES_ADMIN.FPS,
  },
  {
    label: "sidebar.menu.operators",
    path: ROUTES_ADMIN.OPERATORS,
  },
  {
    label: "sidebar.menu.contracts",
    path: ROUTES_ADMIN.CONTRACTS,
  },
  {
    label: "sidebar.menu.settings",
    path: ROUTES_ADMIN.SETTINGS,
  },
  {
    label: "sidebar.menu.company",
    path: ROUTES_ADMIN.COMPANIES,
  },
  {
    label: "sidebar.menu.purpose",
    path: ROUTES_ADMIN.CONTRACT_TYPE,
  },
];

export enum USER_ABSOLUTE_ROUTES {
  SIGNIN = "/login",
  SIGNUP = "/register",
  NOTIFY_FORGOT = "/notify-forgot",
  NOTIFY_REGISTER = "/notify-register",
  FORGOT = "/forgot-password",
  RESET = "/reset-password",
  CONTRACTS = "/contracts",
  CREATE = "/create",
  TUTORIAL = "/tutorial",
  EDIT_EMAIL_SUCCESS = "/edit-email-success",
  EDIT_PASSWORD_SUCCESS = "/edit-password-success",
  SETTINGS = "/settings",
  EDIT_EMAIL = "/edit-email",
  EDIT_PASSWORD = "/edit-password",
  CHECK_EMAIL_USER = "update-email-user",
  UPDATE_EMAIL_SUCCESS = "/update-email-success",
  CONFIRM_CHANGE_EMAIL = "/complete-change-email",
  NOTIFICATION = "/notification",
  MY_PAGE = "/mypage",
  QUESTION = "/question",
  DETAIL = "/detail",
}

export enum USER_RELATIVE_ROUTES {
  MY_PAGE = "mypage",
  HOME = "/",
  CONTRACT_ID = ":id",
  SIGNIN = "login",
  SIGNUP = "register",
  NOTIFY_FORGOT = "notify-forgot",
  NOTIFY_REGISTER = "notify-register",
  FORGOT = "forgot-password",
  RESET = "reset-password",
  VERIFY = "verify",
  CONTRACTS = "contracts",
  CREATE = "create",
  BACK = "../",
  REVIEW = "review",
  DETAIL = "detail",
  UPDATE = "update",
  TUTORIAL = "tutorial",
  EDIT_EMAIL = "edit-email",
  EDIT_EMAIL_SUCCESS = "edit-email-success",
  EDIT_PASSWORD_SUCCESS = "edit-password-success",
  EDIT_PASSWORD = "edit-password",
  CONFIRM_CHANGE_EMAIL = "complete-change-email",
  CHECK_EMAIL_USER = "update-email-user",
  UPDATE_EMAIL_SUCCESS = "update-email-success",
  LINE_LOGIN = "line",
  NOTIFICATION_ID = ":id",
  NOTIFICATION = "notification",
  AUTH_ACTION = "__/auth/action",
}

export enum PURPOSE_RESEND {
  FORGOT = "forgot",
  VERIFY = "verify",
  EDIT_EMAIL = "edit-email",
  EDIT_PASSWORD = "edit-password",
  UPDATE_EMAIL = "update-email",
}
