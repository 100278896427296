import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContractTable } from "./types";

type InitialState = {
  contractList: ContractTable[];
  errors: Record<string, any> | null;
};

const initialState: InitialState = {
  contractList: [],
  errors: null,
};

const contractSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    setContractList: (state, { payload }: PayloadAction<ContractTable[]>) => {
      state.contractList = payload;
    },
  },
});

export default contractSlice;

export const { setContractList } = contractSlice.actions;
