export enum ERRORS_CODE {
  SITE_UNRECOGNIZABLE = "errors.message.site_unrecognizable",
  NOT_PERMISSION_ACCESS = "errors.message.not_permission_access",
  INCORRECT_AUTHENTICATION = "errors.message.incorrect_authentication",
  UNVERIFIED = "errors.message.unverified",
  NOT_APPROVED = "errors.message.not_approved",
  NOT_CREATE_ADMIN = "errors.message.not_create_admin",
  USER_NOT_FOUND = "errors.message.user_not_found",
  VERIFIED = "errors.message.verified",
  EMAIL_EXISTED = "errors.message.email_existed",
  TOKEN_INVALID = "errors.message.token_invalid",
  DATA_INVALID = "errors.message.data_invalid",
  NEW_PASSWORD_MUST_BE_DIFFERENT_FROM_YOUR_PREVIOUS_PASSWORD = "errors.message.new_password_must_be_different",
}

export enum ERRORS_STATUS {
  BadRequest = 400,
  NotAuthenticated = 401,
  PaymentError = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  Timeout = 408,
  Conflict = 409,
  Unprocessable = 422,
  GeneralError = 500,
  NotImplemented = 501,
  Unavailable = 503,
}
