import { Row } from "antd";
import React from "react";
import MessageModalWrapper from "./styles";

type IProps = {
  message: string;
  icon?: string;
  visible: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const MessageModal: React.FC<IProps> = ({
  message,
  icon,
  visible,
  onCancel,
  children,
}) => {
  return (
    <MessageModalWrapper
      centered
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width={350}
      closable={false}
    >
      <Row justify="center">
        {icon && <img className="message-icon" src={icon} alt="MESSAGE_ICON" />}
      </Row>
      <Row justify="center">
        <p className=" message-title">{message}</p>
      </Row>
      {children && <Row justify="center">{children}</Row>}
    </MessageModalWrapper>
  );
};

export default MessageModal;
