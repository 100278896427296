import { MenuProps } from "antd";
import { DataFee, DataRule } from "../types";

type MenuItem = Required<MenuProps>["items"][number];
export const isThousandNumber = (value: number) => value % 10000 === 0;

export const convertPrice = (value: number) => {
  if (value) {
    const formatValue = +value.toString().replace(/[^0-9-]+/g, "");
    if (isThousandNumber(formatValue)) return formatValue / 10000;
    return formatValue;
  }
  return value;
};

export const renderAgeSumaryContent = (item: DataRule) => {
  if (item.title === "detail.rules.title.warranty_period") {
    const warrantyPeriod = item.info?.split(",");

    const startPeriod = warrantyPeriod[0];
    const endPeriod = warrantyPeriod[1];

    if (startPeriod && endPeriod) {
      return `${startPeriod}${item.stage}~${endPeriod}${item.stage}`;
    } else return "---";
  } else {
    return `${item.info}${item.stage}` || "---";
  }
};
export const renderAgeInsuranceFees = (
  item: DataFee,
  defaultMessage: string
) => {
  const warrantPeriod = item.age;
  if (warrantPeriod) {
    const startPeriod = warrantPeriod[0];
    const endPeriod = warrantPeriod[1];

    if (startPeriod && endPeriod) {
      return `${startPeriod}${item.ageStage}~${endPeriod}${item.ageStage}${defaultMessage}`;
    }
  }
  return "";
};

export const parseCurrencies = (value: number | string | null) => {
  if (value === null) return null;
  const valueString = value.toString();
  return valueString.length ? +value.toString().replaceAll(",", "") : null;
};

export const getExperienceYears = (experienceYears: string) =>
  new Date().getFullYear() - new Date(experienceYears).getFullYear() || 0;

export const numberToCurrencies = (value: number) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const getItem = (
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  patch?: any,
  children?: MenuItem[]
): MenuItem => {
  return {
    key,
    icon,
    patch,
    children,
    label,
  } as MenuItem;
};
