import { FormattedDate } from "react-intl";
import { StyledTimeTable } from "./styles";

const TimeTable = ({ time }: { time: string }) => {
  return (
    <StyledTimeTable>
      <span>
        <FormattedDate value={time} day="numeric" month="long" year="numeric" />
      </span>
      <span>
        <FormattedDate value={time} hour="numeric" minute="numeric" />
      </span>
    </StyledTimeTable>
  );
};

export default TimeTable;
