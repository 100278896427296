import { Theme, AdminTheme } from "../types";

export const DEFAULT_THEME: Theme = {
  colors: {
    brand: {
      main: "#546FF6",
      dark: "#3F55C6",
      light: "#E8ECFF",
      brightest: "#EEF0FB",
    },
    secondary: {
      main: "#EB8CA4",
      dark: "#E4597C",
      light: "#FAE3E9",
    },
    back: {
      main: "#F5FAFF",
      pink_gradation:
        "linear-gradient(180deg, #FAE3E9 -10.23%, #FFFFFF 114.2%)",
      blue_gradation:
        "linear-gradient(180deg, #E8ECFF -10.23%, #FFFFFF 114.2%)",
      gray: "#FAFAFA",
    },
    text: {
      main: "#272C36",
      gray: "#666666",
      lightgray: "#A4A4A4",
      brightest: "#DEDEDE",
      link: "#546FF6",
      pink: "#E4597C",
    },
    button: {
      blue: "linear-gradient(180deg, #546FF6 0%, #3E55C5 100%)",
      pink: "linear-gradient(180deg, #EB8CA4 0%, #CD4B6C 100%)",
    },
    border: {
      main: "#D6E0FA",
      light: "#E4EAFA",
    },
    others: {
      lightgray: "#F1F1F1",
      yellow: "#FFEA44",
      yellow_gradation: "linear-gradient(180deg, #FFF28C 0%, #FFEA44 100%)",
    },
    system: {
      white: "#FFFFFF",
      grayout: "#E9EFF8",
      disable: "#D6DCE7",
    },
    orange: {
      100: "#EB9500",
      50: "#FFF9EE",
      10: "#FFEFD4",
      text: "#F47500",
      150: "#EDA21F",
      200: "#F47500",
    },
    navy: {
      100: "#0B1F65",
      60: "#ECF3FD",
      50: "#F8FBFF",
      10: "#DDEAF5",
      5: "#E0E2F2",
    },
    gray: {
      2: "#4F4F4F",
      3: "#828282",
      4: "#BDBDBD",
      5: "#E0E0E0",
      6: "#F2F2F2",
      7: "#FAFAFA",
      8: "#98A3BE",
      9: "#eff2f8",
    },
    blue: {
      1: "#2F80ED",
      2: "#1877f2",
      3: "#4e9ae9",
    },
    red: {
      main: "#E4597C",
      100: "#dd4b39",
      200: "#EB8CA4",
      300: "#FAE3E9",
    },
    green: {
      main: "#29CC97",
      100: "#35BD42",
      200: "#00b900",
    },
  },
};

export const THEME_ADMIN: AdminTheme = {
  colors: {
    blue: {
      main: "#3751FF",
      100: "#E8ECFF",
      500: "#D6E0FA",
      600: "#6C7EF5",
      700: "#546FF6",
      800: "#3F55C6",
      boxShadow: "0px 4px 12px rgba(55, 81, 255, 0.24)",
    },
    black: {
      main: "#252733",
      100: "#A5A8B8",
      500: "#363740",
    },
    green: {
      main: "#29CC97",
      100: "#35BD42",
    },
    yellow: {
      main: "#FEC400",
      100: "#FFEA44",
      200: "#eb9500",
    },
    red: {
      main: "#E4597C",
      100: "#FF4D4F",
      200: "#EB8CA4",
      300: "#FAE3E9",
    },
    white: {
      main: "#FFFFFF",
      100: "#E9EFF8",
    },
    gray: {
      main: "#A4A6B3",
      light: "#DDE2FF",
      100: "#FCFDFE",
      200: "#F0F1F7",
      300: "#9FA2B4",
      400: "#4B506D",
      500: "#F7F8FC",
      600: "#DFE0EB",
      700: "#C5C7CD",
      800: "#666666",
    },
  },
};
