import { ROUTES_ADMIN } from "@milize/common/constants/routes.constant";
import { useLogout } from "features/auth/hooks";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export type ReceivedProps = Record<string, any>;

const useHook = (props: ReceivedProps) => {
  const { formatMessage: t } = useIntl();
  const navigate = useNavigate();
  const { handleLogout } = useLogout();
  const appName = `${t({ id: "app.name" })}`;

  const onClickMenuItem = (item: any) => {
    const { key } = item;
    if (key && Object.values(ROUTES_ADMIN).includes(key)) {
      if (key === ROUTES_ADMIN.LOGOUT) handleLogout();
      else navigate(key);
    }
  };

  return {
    ...props,
    appName,
    onClickMenuItem,
  };
};

export type Props = ReturnType<typeof useHook>;

export default useHook;
