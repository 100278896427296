import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { LoadingWrapper } from "./styled";

const Loading: React.FC = () => {
  return (
    <LoadingWrapper>
      <Spin indicator={<LoadingOutlined className="loading-icon" spin />} />
    </LoadingWrapper>
  );
};

export default Loading;
