import { InputProps, PasswordProps, TextAreaProps } from "formik-antd";

import {
  InputPasswordWrapper,
  InputTextareaWrapper,
  InputWrapper,
} from "./styles";

interface InputType extends React.ForwardRefExoticComponent<InputProps> {
  Password: React.ForwardRefExoticComponent<PasswordProps>;
  TextArea: React.ForwardRefExoticComponent<TextAreaProps>;
}

const InputText = InputWrapper;

const TypedInput = InputText as unknown as InputType;

TypedInput.Password = InputPasswordWrapper;

TypedInput.TextArea = InputTextareaWrapper;

export default TypedInput;
