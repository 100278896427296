import { Layout, Menu } from "antd";
import styled from "styled-components";

export const SidebarWrapper = styled(Layout.Sider)`
  height: 100vh;
  background-color: #eb9500;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  .sidebar-brand {
    margin-top: 40px;
    margin-bottom: 63px;
    text-align: center;

    h3 {
      color: #fff;
      font-size: 19px;
      font-weight: 700;
      margin-top: 10px;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  background-color: transparent;
  border: none;

  .ant-menu-item {
    height: 56px;
    display: flex;
    padding-left: 30px;
    padding-right: 24px;
    align-items: center;
    margin: 0 !important;
    font-size: 16px;
    font-weight: 400;

    span,
    a {
      color: ${({ theme }) => theme.colors.gray.light};
    }

    &-disabled a {
      color: ${({ theme }) => theme.colors.gray.main} !important;
    }

    &.link-active,
    &.ant-menu-item-selected {
      position: relative;
      background-color: transparent !important;

      span,
      a {
        color: #fff;
        font-weight: bold;
      }

      &::before {
        content: "";
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.gray.light};
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: none;
        transform: none;
        background-color: #fff;
        opacity: 0.2;
      }

      &:hover {
        span {
          color: #fff;
        }
      }

      .ant-menu-item-icon {
        path {
          fill: #fff;
          opacity: 1;
        }
      }
    }

    &-icon {
      margin-right: 24px;
      width: 16px;
      height: 16px;
      path {
        fill: ${({ theme }) => theme.colors.gray.light};
        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
          margin 0.3s, color 0.3s;
      }
    }

    &:not(.ant-menu-item-disabled):hover {
      .ant-menu-item-icon {
        path {
          fill: #fff;
          opacity: 1;
        }
      }

      span,
      a {
        color: #fff;
      }
    }
  }
`;
