//
import { loggedInUserSelector } from "features/auth/selectors";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const CommonRoute = () => {
  const loggedInUser = useSelector(loggedInUserSelector);

  if (loggedInUser) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default CommonRoute;
