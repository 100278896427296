import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "@milize/common/types";

type InitialState = {
  loggedInUser: null | User;
  errors: Record<string, any> | null;
  mailResend: string;
};

const initialState: InitialState = {
  loggedInUser: null,
  errors: null,
  mailResend: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<{ user: User }>) {
      state.loggedInUser = action.payload.user;
    },
    logout(state) {
      state.loggedInUser = null;
    },
    setErrorsAuth: (
      state,
      action: PayloadAction<Record<string, any> | null>
    ) => {
      state.errors = action.payload
        ? {
            message:
              action.payload.message || action.payload?.errors[0]?.message,
          }
        : null;
    },
    setMailResend: (state, action: PayloadAction<string>) => {
      state.mailResend = action.payload;
    },
  },
});

export default authSlice;

export const { loginSuccess, logout, setErrorsAuth, setMailResend } =
  authSlice.actions;
