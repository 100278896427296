import { Form } from "formik-antd";
import styled from "styled-components";

export const StyledFormAuth = styled(Form)`
  padding: 40px 32px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black.main};

  .form-title {
    color: ${({ theme }) => theme.colors.black.main};
    font-size: 24px;
    font-weight: 700;
    text-transform: lowercase;

    &::first-line {
      text-transform: capitalize;
    }
  }

  .form-desc {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray["300"]};
  }
`;
