import { Layout } from "antd";
import styled from "styled-components";
import { WIDTH_SIDEBAR } from "@milize/common/constants/routes.constant";

export const LayoutWrapper = styled(Layout)`
  .ant-layout {
    min-height: 100vh;
    margin-left: ${WIDTH_SIDEBAR}px;
    background-color: ${({ theme }) => theme.colors.gray["500"]};

    &-content {
      padding: 24px 32px 32px;
    }
  }
`;
