export enum API_ENDPOINTS {
  REGISTER = "register",
  USERS = "users",
  USER_ACTIVE = "user-active",
  RESEND_VERIFY = "resend-verify",
  APPROVE = "approve",
  FILES = "files",
  CONTRACTS = "contracts",
  CONTRACT_METADATA = "contract-metadata",
  CONTRACT_REJECT = "contract-reject",
  CONVERT_CONTRACT = "convert-contract",
  UPDATE_CONTRACT = "update-contract",
  CONTRACT_REVIEW = "contract-review",
  COMMENT_READERS = "comment-readers",
  COMMENT_CONTROL = "comment-control",
  COMMENTS = "comments",
  USER_METADATA = "user-metadata",
  COMPANIES = "companies",
  CONTRACT_TYPES = "contract-types",
  SETTINGS = "settings",
  RESET_PASSWORD = "reset-password",
  FORGOT_PASSWORD = "forgot-password",
  PROFILE = "profile",
  ANALYTICS = "analytics",
  USER_STATISTIC = "user-statistic",
  EDIT_EMAIL = "change-email",
  CONFIRM_CHANGE_EMAIL = "confirm-change-email",
  SNS_EMAIL = "sns-email",
  SNS = "sns",
  NOTIFICATIONS = "notifications",
  LINE = "line",
  OCCUPATIONS = "occupations",
  LIVES = "lives",
  DIAGNOSTICS = "diagnostics",
}
