//
import { Layout } from "components/common";
import { loggedInUserSelector } from "features/auth/selectors";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const loggedInUser = useSelector(loggedInUserSelector);

  if (!loggedInUser) {
    return <Navigate to="/login" />;
  }

  return <Layout />;
};

export default PrivateRoute;
