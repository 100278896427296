import { USER_ACTIVE_STATUS } from "@milize/common/constants";
import { CONTRACT_STATUS } from "@milize/common/constants/contract.constant";
import { FC } from "react";
import { useIntl } from "react-intl";
import { StyledLabel } from "./styles";

export enum STATUS_COMMON {
  NOT_VERIFIED = "NOT_VERIFIED",
  LIKE = "LIKE",
  DISLIKE = "DISLIKE",
}

interface LabelStatusProps {
  status: USER_ACTIVE_STATUS | STATUS_COMMON | CONTRACT_STATUS;
}

const LabelStatus: FC<LabelStatusProps> = ({ status }) => {
  const { formatMessage: t } = useIntl();

  const getLabelStatus = () => {
    switch (status) {
      case USER_ACTIVE_STATUS.PENDING:
      case CONTRACT_STATUS.PENDING: {
        return t({ id: "status.pending" });
      }
      case USER_ACTIVE_STATUS.IN_ACTIVE: {
        return t({ id: "status.inactive" });
      }
      case USER_ACTIVE_STATUS.ACTIVE: {
        return t({ id: "status.approved" });
      }
      case STATUS_COMMON.NOT_VERIFIED: {
        return t({ id: "status.notVerified" });
      }
      case CONTRACT_STATUS.NEW: {
        return t({ id: "status.new" });
      }
      case CONTRACT_STATUS.REJECT: {
        return t({ id: "status.reject" });
      }
      case STATUS_COMMON.DISLIKE: {
        return t({ id: "status.disLike" });
      }
      case STATUS_COMMON.LIKE: {
        return t({ id: "status.like" });
      }
    }
  };

  return <StyledLabel status={status}>{getLabelStatus()}</StyledLabel>;
};

export default LabelStatus;
