import LogoSrc from "@milize/common/assets/images/yoshinani_logo.svg";
import {
  ROUTES_ADMIN,
  WIDTH_SIDEBAR,
} from "@milize/common/constants/routes.constant";
import { FC, memo } from "react";
import useHook, { Props, ReceivedProps } from "./hook";
import { SidebarWrapper, StyledMenu } from "./styles";
import { ReactComponent as ContractIcon } from "../../../common/assets/images/ic_contract.svg";
import { ReactComponent as ProfileIcon } from "../../../common/assets/images/ic_profile.svg";
import { ReactComponent as LogoutIcon } from "../../../common/assets/images/ic_logout.svg";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { getItem } from "@milize/common/utils/contract";
import { MenuProps } from "antd";

type MenuItem = Required<MenuProps>["items"][number];

const SidebarLayout: FC<Props> = ({ onClickMenuItem, appName }) => {
  const { formatMessage: t } = useIntl();
  const location = useLocation();
  const items: MenuItem[] = [
    getItem(
      t({ id: "sidebar.menu.contracts" }),
      ROUTES_ADMIN.CONTRACTS,
      <ContractIcon />
    ),
    getItem(
      t({ id: "sidebar.menu.profile" }),
      ROUTES_ADMIN.PROFILE,
      <ProfileIcon />
    ),
    getItem(
      t({ id: "sidebar.menu.logout" }),
      ROUTES_ADMIN.LOGOUT,
      <LogoutIcon />
    ),
  ];

  return (
    <SidebarWrapper width={WIDTH_SIDEBAR}>
      <div className="sidebar-brand">
        <a href="/">
          <img src={LogoSrc} alt="logo" />
        </a>
        <h3>{appName}</h3>
      </div>
      <StyledMenu
        onClick={onClickMenuItem}
        items={items}
        selectedKeys={[
          ROUTES_ADMIN.HOME + location.pathname.split(ROUTES_ADMIN.HOME)[1],
        ]}
      ></StyledMenu>
    </SidebarWrapper>
  );
};

const Sidebar: FC<ReceivedProps> = (props) => {
  return <SidebarLayout {...useHook(props)} />;
};

export default memo(Sidebar);
