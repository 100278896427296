import styled from "styled-components";

export const StyledGroupTable = styled("div")`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray["600"]};
  padding: 32px 0;
  background-color: white;

  h3 {
    color: ${({ theme }) => theme.colors.black.main};
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 36px;
    padding: 0 32px;
  }
`;
