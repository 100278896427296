import Loading from "components/common/Loading";
import { FC } from "react";
import useReAuthenticate, { Props, ReceivedProps } from "./hook";

const ReAuthenticateLayout: FC<Props> = ({ loading, children }) => {
  if (loading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  return <>{children}</>;
};

const ReAuthenticate: FC<ReceivedProps> = (props) => (
  <ReAuthenticateLayout {...useReAuthenticate(props)} />
);

export default ReAuthenticate;
