import styled from "styled-components";

export const StyledTimeTable = styled.div`
  display: flex;
  flex-direction: column;

  span {
    &:last-child {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.gray["700"]};
    }
  }
`;
