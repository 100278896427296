import { TableProps } from "antd";
import { Table } from "components/primitives";
import { PropsWithChildren } from "react";
import { StyledGroupTable } from "./styles";

export interface GroupTableProps<T = any> {
  title: string;
  tableProps: TableProps<T>;
}

function GroupTable<T = any>({
  title,
  tableProps,
}: PropsWithChildren<GroupTableProps<T>>) {
  return (
    <StyledGroupTable>
      <h3>{title}</h3>
      <Table {...tableProps} />
    </StyledGroupTable>
  );
}

export default GroupTable;
