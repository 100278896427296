export * from "./api.constant";
export * from "./token.constant";
export * from "./form.constant";
export * from "./errors.constant";
export * from "./contract.constant";
export * from "./comment.constant";
export * from "./settings.constant";
export * from "./theme";

export enum USER_ROLES {
  ADMIN = "ADMIN",
  OPERATOR = "OPERATOR",
  FP = "FP",
  USER = "USER",
}

export enum USER_ACTIVE_STATUS {
  ACTIVE = "ACTIVE",
  IN_ACTIVE = "IN_ACTIVE",
  PENDING = "PENDING",
}

export enum FORM_EDIT_PASSWORD {
  password = "",
  newPassword = "",
  confirmPassword = "",
}
export enum LOCALE {
  en = "en",
  ja = "ja",
}

export enum REVIEW_REACTION {
  REVIEW = 0,
  LIKE = 1,
}

export enum STATUS_COMMON {
  NOT_VERIFIED = "NOT_VERIFIED",
  LIKE = "LIKE",
  DISLIKE = "DISLIKE",
  CONVERTED = "CONVERTED",
}

export enum TUTORIAL_FIELD_TYPE {
  CHECKBOX_LARGE = "CHECKBOX_LARGE",
  CHECKBOX_NORMAL = "CHECKBOX_NORMAL",
  RADIO_LARGE = "RADIO_LARGE",
  RADIO_NORMAL = "RADIO_NORMAL",
  RADIO_SMALL = "RADIO_SMALL",
  SELECT = "SELECT",
  INPUT = "INPUT",
  SPLASH = "SPLASH",
  INPUT_PULLDOWN = "INPUT_PULLDOWN",
}

export enum USER_FIELD {
  ID = "id",
  EMAIL = "email",
  NAME = "name",
  AVATAR = "avatar",
  BIO = "bio",
  ROLE = "role",
  STATUS = "status",
  IS_VERIFIED = "isVerified",
  METADATA = "metadata",
  EXPERIENCE_YEARS = "experienceYears",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
}

export enum USER_METADATA_FIELD {
  ID = "id",
  SEX = "sex",
  AGE = "age",
  OCCUPATION = "occupation",
  BUSINESS = "business",
  EMPLOYMENT_PLACE_SIZE = "employmentPlaceSize",
  FAMILY = "family",
  ANNUAL_INCOME = "annualIncome",
  MONTHLY_LIVING_EXPENSES = "monthlyLivingExpenses",
  HOUSE_FORM = "houseForm",
  SAVINGS_AMOUNT = "savingsAmount",
  STOCK = "stock",
  INSURANCE = "insurance",
  REAL_ESTATE = "realEstate",
  OTHER_ASSETS = "otherAssets",
  DESIRE_AFTER_DEATH = "desireAfterDeath",
  PAY_FINANCIALLY = "payFinancially",
  HEALTH_STATUS = "healthStatus",
  USER_ID = "userId",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  CHILDREN = "children",
  AGE_CHILDREN = "ageChildren",
  ASSETS = "asset",
  LIVE = "lives",
  MANAGED_ASSETS = "managedAssets",
  ASSETS_UNDER_MANAGEMENT = "assetsUnderManagement",
}

export enum CREATE_CONTRACT_FIELD {
  COMPANY = "company",
  PURPOSE = "purpose",
  IMAGES = "images",
}

export enum COMPANY_FIELD {
  ID = "id",
  NAME = "name",
}

export enum CONTRACT_TYPE_FIELD {
  ID = "id",
  TITLE = "name",
}
