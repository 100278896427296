import styled from "styled-components";

export const StyledActionTable = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ic-more {
    display: none;
  }
`;
