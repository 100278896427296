import { Button } from "antd";
import styled from "styled-components";

export const ButtonWrapper = styled(Button)`
  width: 100%;
  height: 48px;
  border-radius: 8px;

  &.ant-btn-primary {
    /* background-color: ${({ theme }) => theme.colors.blue.main}; */
    /* color: white; */
    box-shadow: ${({ theme }) => theme.colors.blue.boxShadow};
  }

  &.ant-btn-default {
    background-color: white;
    color: ${({ theme }) => theme.colors.blue.main};
    border-color: ${({ theme }) => theme.colors.blue.main};
  }

  &.btn-danger {
    color: white;
    box-shadow: ${({ theme }) => theme.colors.blue.boxShadow};
    background-color: ${({ theme }) => theme.colors.red["100"]};
    border-color: ${({ theme }) => theme.colors.red["100"]};
  }
`;
