import React from "react";
import { Dropdown, Menu } from "antd";
import { ReactComponent as IconMore } from "assets/images/ic_more.svg";
import { StyledActionTable } from "./styles";

export type OptionAction = {
  label: string;
  onClick?: () => void;
};

interface ActionTableProps {
  options: OptionAction[];
}

const ActionTable = ({ options }: ActionTableProps) => {
  const renderMenus = () => {
    return (
      <Menu style={{ width: "150px" }}>
        {options.map((it, index) => (
          <React.Fragment key={it.label}>
            <Menu.Item
              key={it.label}
              onClick={it.onClick}
              style={{ fontWeight: "bold" }}
            >
              {it.label}
            </Menu.Item>
            {index + 1 < options.length && <Menu.Divider />}
          </React.Fragment>
        ))}
      </Menu>
    );
  };

  return (
    <StyledActionTable>
      <Dropdown overlay={renderMenus}>
        <IconMore className="ic-more" />
      </Dropdown>
    </StyledActionTable>
  );
};

export default ActionTable;
