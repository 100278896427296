export enum CONTRACT_STATUS {
  PENDING = "PENDING",
  NEW = "NEW",
  REJECT = "REJECTED",
  CONVERTED = "CONVERTED",
}

export const ASSETS_LEVER_OPTIONS = Array.from({ length: 44 }, (_, index) => ({
  value: index < 31 ? index * 100 : 3000 + (index - 30) * 500,
  label: (index < 31 ? index * 100 : 3000 + (index - 30) * 500).toString(),
}));

// export const ASSETS_AGE_OPTIONS = Array.from({ length: 76 }, (_, index) => ({
//   value: index,
// })).filter((item) => item.value > 17);

export const ASSETS_AGE_OPTIONS = Array.from(
  { length: 58 },
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  (init: number = 18, index) => ({
    value: init + index,
  })
);

export const ASSETS_CHILD_OPTIONS = Array.from(
  { length: 5 },
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  (init: number = 1, index) => ({
    value: init + index,
  })
);

export const ASSETS_AGE_CHILD_OPTIONS = Array.from(
  { length: 28 },
  (_, index) => ({
    value: index,
  })
);

export const ASSETS_MONTHLY_LIVING_EXPENSES_OPTIONS = Array.from(
  { length: 20 },
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  (init: number = 1, index) => ({
    value: (init + index) * 5,
  })
);

export enum CONTRACT_FIELD {
  ID = "id",
  CONVERTER_ID = "converterId",
  OWNER_ID = "ownerId",
  COMPANY = "company",
  PURPOSE = "purpose",
  IMAGES = "images",
  STATUS = "status",
  COMMENTS = "comments",
  OWNER = "owner",
  METADATA = "metadata",
  READERS = "readers",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  LIKES = "likes",
  DISLIKES = "dislikes",
  NEWS = "news",
  REASON = "reason",
}
