import { ROUTES_ADMIN } from "@milize/common/constants/routes.constant";
import { CommonRoute, PrivateRoute } from "components/common";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const SignInPage = lazy(() => import("features/auth/SignIn"));
const SignUpPage = lazy(() => import("features/auth/SignUp"));
const ResetPasswordPage = lazy(() => import("features/auth/ResetPassword"));
const ForgotPasswordPage = lazy(() => import("features/auth/ForgotPassword"));
const NotFoundPage = lazy(() => import("features/auth/404"));
const ContractListPage = lazy(() => import("features/contracts/Contracts"));
const ProfilePage = lazy(() => import("features/profile"));
const ContractDetailPage = lazy(
  () => import("features/contracts/ContractDetail")
);
// const AuthActionPage = lazy(() => import("../pages/AuthAction/index"));
const VerificationPage = lazy(() => import("features/auth/Verification"));
const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Routes>
          <Route element={<CommonRoute />}>
            <Route path={ROUTES_ADMIN.SIGN_IN} element={<SignInPage />} />
            <Route path={ROUTES_ADMIN.SIGN_UP} element={<SignUpPage />} />
            <Route
              path={ROUTES_ADMIN.RESET_PASSWORD}
              element={<ResetPasswordPage />}
            />
            <Route
              path={ROUTES_ADMIN.FORGOT_PASSWORD}
              element={<ForgotPasswordPage />}
            />
            <Route path={ROUTES_ADMIN.VERIFY} element={<VerificationPage />} />
            {/* <Route path="__/auth/action" element={<AuthActionPage />} /> */}
          </Route>

          <Route path={ROUTES_ADMIN.HOME} element={<PrivateRoute />}>
            <Route
              path={ROUTES_ADMIN.CONTRACTS}
              element={
                <Suspense fallback={null}>
                  <ContractListPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.DETAIL_CONTRACT}
              element={
                <Suspense fallback={null}>
                  <ContractDetailPage />
                </Suspense>
              }
            />
            <Route
              path={ROUTES_ADMIN.PROFILE}
              element={
                <Suspense fallback={null}>
                  <ProfilePage />
                </Suspense>
              }
            />
            <Route index element={<Navigate to={ROUTES_ADMIN.CONTRACTS} />} />
          </Route>
          <Route
            path="*"
            element={
              <Suspense fallback={null}>
                <NotFoundPage />
              </Suspense>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
