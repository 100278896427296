import { USER_ACTIVE_STATUS } from "@milize/common/constants";
import { CONTRACT_STATUS } from "@milize/common/constants/contract.constant";
import styled from "styled-components";
import { STATUS_COMMON } from ".";

interface Props {
  status: string;
}

export const StyledLabel = styled.span<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 24px;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case USER_ACTIVE_STATUS.ACTIVE:
        return theme.colors.green.main;
      case USER_ACTIVE_STATUS.PENDING:
        return theme.colors.yellow.main;
      case STATUS_COMMON.NOT_VERIFIED:
        return theme.colors.blue.main;
      case CONTRACT_STATUS.NEW:
        return theme.colors.blue["700"];
      case STATUS_COMMON.LIKE:
        return theme.colors.green["100"];
      case STATUS_COMMON.DISLIKE:
        return theme.colors.red.main;
      default:
        return theme.colors.black.main;
    }
  }};
  border-radius: 100px;
  text-transform: uppercase;
  color: white;
  font-size: 11px;
`;
