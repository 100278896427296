import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import feathers, {
  registerService,
  resendVerifyService,
  verifyMailService,
} from "libs/feathers";
import { loginSuccess, logout, setErrorsAuth, setMailResend } from "./slice";
import { UserRegister } from "./SignUp/validation";
import { UserLogin } from "./SignIn/validation";
import { useLocation, useSearchParams } from "react-router-dom";
import { Modal, notification } from "antd";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ROUTES_ADMIN } from "@milize/common/constants/routes.constant";
import { errorsAuthSelector } from "./selectors";
import { ERRORS_CODE } from "@milize/common/constants/errors.constant";

export const useMatchRoute = () => {
  const { pathname } = useLocation();

  const isMatch = (path?: string) =>
    path &&
    ((path === ROUTES_ADMIN.HOME && pathname === ROUTES_ADMIN.HOME) ||
      (path && path !== ROUTES_ADMIN.HOME && pathname.startsWith(path)));

  return { isMatch };
};

export const useErrorsAuth = () => {
  const dispatch = useDispatch();
  const errorsAuth = useSelector(errorsAuthSelector);

  const resetErrorsAuth = () => {
    dispatch(setErrorsAuth(null));
  };

  return { errorsAuth, resetErrorsAuth };
};

export const useLogin = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const handleLogin = async ({ email, password }: UserLogin) => {
    try {
      setState({ ...state, loading: true });
      const { user } = await feathers.authenticate({
        email,
        password,
        strategy: "local",
      });
      setState((prev) => ({ ...prev, loading: false }));
      dispatch(loginSuccess({ user }));
    } catch (error: any) {
      if (error?.message === ERRORS_CODE.UNVERIFIED) {
        dispatch(setMailResend(email));
      }
      setState((prev) => ({ ...prev, loading: false, error }));
      dispatch(setErrorsAuth(error));
    }
  };

  return { ...state, handleLogin };
};

export const useRegister = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState<{
    loading: boolean;
    error: { [key: string]: any } | null;
    isSuccess: boolean;
    email: string;
  }>({
    loading: false,
    error: null,
    isSuccess: false,
    email: "",
  });

  const handleRegister = async (values: UserRegister) => {
    try {
      setState({ ...state, loading: true });
      const { lastName, firstName, email, ...rest } = values;
      const finalData = {
        ...rest,
        email,
        name: `${lastName} ${firstName} `,
      };
      await registerService.create(finalData);
      setState((prev) => ({
        ...prev,
        loading: false,
        error: null,
        isSuccess: true,
        email: email,
      }));
    } catch (error: any) {
      dispatch(setErrorsAuth(error));
      setState((prev) => ({ ...prev, loading: false, error }));
    }
  };

  return { ...state, handleRegister };
};

export const useVerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<{ [key: string]: any } | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleVerifyMail = async () => {
      try {
        setLoading(true);
        const email = searchParams.get("email");
        const token = searchParams.get("token");
        await verifyMailService.create({ email, token });
        setLoading(false);
      } catch (err: any) {
        setError(err);
        setLoading(false);
      }
    };

    handleVerifyMail();
  }, [searchParams]);

  return { error, loading };
};

export const useResendMail = () => {
  const dispatch = useDispatch();
  const { formatMessage: t } = useIntl();

  const handleResend = async (email: string) => {
    try {
      await resendVerifyService.create({ email });
      notification.success({
        message: t({ id: "form.resend.success" }),
      });
    } catch (err: any) {
      dispatch(setErrorsAuth(err));
    }
  };

  return { handleResend };
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { messages } = useIntl();

  const handleLogout = async () => {
    try {
      await feathers.logout();
      dispatch(logout());
      navigate(ROUTES_ADMIN.SIGN_IN);
    } catch (error: any) {
      const errorMessage = error?.errors?.[0]?.message || error?.message;

      Modal.warning({
        content: messages[errorMessage] || errorMessage,
      });
    }
  };

  return { handleLogout };
};
