import { Modal } from "antd";
import styled from "styled-components";

const MessageModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 18px;
  }

  .message-icon {
    min-height: 32px;
    margin-bottom: 16px;
  }

  .message-title {
    color: ${({ theme }) => theme.colors.black.main};
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.6px;
    text-align: center;
  }

  .ant-btn {
    margin-top: 20px;
  }
  .btn-modal__error:hover {
    background-color: ${({ theme }) => theme.colors.white[100]};
  }
`;

export default MessageModalWrapper;
