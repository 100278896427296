import enUS from "antd/es/locale/en_US";
import { LOCALE } from "@milize/common/constants";

import enMessagesCommon from "@milize/common/locales/en_US.json";
import enMessages from "../locales/en_US.json";

const EnLang = {
  messages: {
    ...enMessagesCommon,
    ...enMessages,
  },
  antd: enUS,
  locale: LOCALE.en,
};

export default EnLang;
