import { FormItem } from "formik-antd";
import styled from "styled-components";

export const FormItemWrapper = styled(FormItem)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black.main};

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray["300"]};
    text-transform: uppercase;
    font-weight: 600;

    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      position: absolute;
      right: -8px;
      margin-right: 0;
    }
  }

  .ant-form-item-explain-error {
    font-size: 12px;
    margin: 4px 0;
    text-align: left;
  }
`;
