import { Select } from "formik-antd";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.gray["100"]};
    border-color: ${({ theme }) => theme.colors.gray["200"]};

    .ant-select-selection-search-input {
      height: 100%;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 42px;
    }
  }
`;
